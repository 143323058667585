<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Discount Codes</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Discount Codes"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-ecommerce-ecommerce-coupon-codes' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Discount Codes
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="coupons"
            no-data-text="No codes found"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="
                  new Date().toISOString().substring(0, 10) > item.expires_at
                "
                label
                color="error"
                text-color="white"
                >Expired</v-chip
              >
              <v-chip
                v-else-if="
                  new Date().toISOString().substring(0, 10) < item.created_at
                "
                label
                color="warning"
                text-color="white"
                >Scheduled</v-chip
              >
              <v-chip v-else label color="success">Active</v-chip>
            </template>

            <template v-slot:item.used="{ item }">
              {{ item.codes.length }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="openRestore(item)"
                  >
                    <v-icon small>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Reactivate Code</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Code</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.coupon.code }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Ecommerce",
          disabled: true,
        },
        {
          text: "Coupon Codes",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Code", value: "code" },
        { text: "Status", value: "status" },
        { text: "Start Date Time", value: "formatted_dates.created_at" },
        { text: "Finish Date Time", value: "formatted_dates.expires_at" },
        { text: "Used", value: "used" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      restoreDialog: {
        dialog: false,
        coupon: {},
        loading: false,
      },
    };
  },

  methods: {
    openRestore(coupon) {
      this.restoreDialog.coupon = coupon;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.coupon = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("ecommerce/couponCodesStore/restoreCoupon", {
          appId,
          couponId: this.restoreDialog.coupon.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },

  computed: {
    coupons() {
      let coupons =
        this.$store.getters["ecommerce/couponCodesStore/allArchivedCoupons"];

      if (this.searchTerm !== "") {
        coupons = coupons.filter((c) => {
          const code = c.code.toLowerCase();
          return code.includes(this.searchTerm.toLowerCase());
        });
      }

      return coupons;
    },
  },
};
</script>
